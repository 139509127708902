import Rails, { linkClickSelector } from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "@fortawesome/fontawesome-free/js/all"
import "bootstrap"
import "@popperjs/core"
import "../images/slogo.png"
import "../images/default_profile.png"
import "../channels/jquery.custom-scrollbar" 

require("jquery");

Rails.start()
Turbolinks.start()
ActiveStorage.start()

$(document).on('turbolinks:load', function() {
    $('#status').on('change', function(){
        console.log($(this).val())
        if($(this).val() > 3 ){
            $("#external-note").show();
        } else {
            $("#external-note").hide();
        }
        if($(this).val() > 2 ){
            $('#submit-applicant').attr("disabled", false);
        }
        if($(this).val() == 3 || $(this).val() == 4 ){
            $("#do_not_use").show();
        } else {
            $("#do_not_use").hide();
        }
        if($(this).val() == 4){
            $("#canned_response_approved").show();
            $("#applicant_external_notes").val("");
        } else {
            $("#canned_response_approved").hide();
        }
        if($(this).val() == 5){
            $("#canned_response_rejected").show();
            $("#applicant_external_notes").val("");
        } else {
            $("#canned_response_rejected").hide();
        }

    });

    var miss_ncert = 'Your application has been approved, but you are not in compliance yet. You will not be able to accept a signing until we receive your Notary Certificate. The one you provided is not valid. Please reply to this email with a copy of the Notary Certificate attached.'
    var miss_bgc = 'Your application has been approved, but you are not in compliance yet. You will not be able to accept a signing until we receive your Background Check. The one you provided is not valid. Please reply to this email with a copy of your Background Check attached that meets the Signing Professional Workgroup’s standards: https://signingprofessionalsworkgroup.org/background-screening-standards' + 
                    'If you need a Background Check, then you can order one from Crimcheck for $35. We do not mark up this price. And we realize there are other vendors (ex: NNA) that provide valid background checks, which we will gladly accept.' + 
                    '\nTo order from Crimcheck, go to: https://app.efetch.com/#/applicant/registration/package/10yearsignatureclosers' + 
                    '\nThank you for sending that document.' + 
                    '\n- Signature Closers Compliance Team'

    var miss_EO = 'Your application has been approved, but you are not in compliance yet. You will not be able to accept a signing until we receive a copy of your Errors and Omissions (E&O) insurance. The one you provided is not valid. Please reply to this email with a copy of the E&O attached.' + 
                '\nThank you for sending that document.' + 
                '\n- Signature Closers Compliance Team'

    var miss_w9 = 'Your application has been approved; however, we need a copy of your Form W-9. Technically, you’ll be able to accept signings, but we will not be able to pay you until we have your W-9 on file and you have entered your tax information in your profile.' + 
                '\nThank you for replying with your W-9 attached.' + 
                '\n- Signature Closers Compliance Team'
    
    var miss_other_compliance = 'Your application has been approved, but you are not in compliance yet. You will not be able to accept a signing until we receive a copy of your TYPE MISSING DOCUMENT HERE.' + 
                                '\nThank you for sending that missing document.' + 
                                '\n- Signature Closers Compliance Team'
    
    var miss_other_incompliance = 'Your application has been approved, but we need the following missing document:' +
                                '\nTYPE MISSING DOCUMENT HERE' +
                                '\nThank you for sending that missing document.' + 
                                '\n- Signature Closers Compliance Team'

    $('#canned-responses-approved').on('change', function(){
        console.log($('#canned-responses-approved').val())
        
        if($('#canned-responses-approved').val() == 1){
            $("#applicant_external_notes").val(miss_ncert);
        } else if($('#canned-responses-approved').val() == 2){
            $("#applicant_external_notes").val(miss_bgc);
        } else if($('#canned-responses-approved').val() == 3){
            $("#applicant_external_notes").val(miss_EO);
        }else if($('#canned-responses-approved').val() == 4){
            $("#applicant_external_notes").val(miss_w9);
        }else if($('#canned-responses-approved').val() == 5){
            $("#applicant_external_notes").val(miss_other_compliance);
        }else if($('#canned-responses-approved').val() == 6){
            $("#applicant_external_notes").val(miss_other_incompliance);
        }else {
            $("#applicant_external_notes").val("");
        }

    });

    var m1 = 'You already have an account on our site, which is why we are rejecting your application. Please use the forget password link if you do not remember your password for our site (https://www.signatureclosers.com/account).'
    var m2 = 'Your application was rejected due to the file format you used when uploading your documents. You are invited to re-apply, but please scan each document individually and save them as PDFs. Upload the PDFs with your application.'
    var m3 = 'Unfortunately, your application has been rejected due to at least one missing document. Please review the registration form and provide valid copies of the required documents when you apply again: https://www.signatureclosers.com/signing-agent-form.html'
    $('#canned_response_rejected').on('change', function(){

        if($('#canned-responses-rejected').val() == 1){
            $("#applicant_external_notes").val(m1);
        } else if($('#canned-responses-rejected').val() == 2){
            $("#applicant_external_notes").val(m2);
        } else if($('#canned-responses-rejected').val() == 3){
            $("#applicant_external_notes").val(m3);
        }else {
            $("#applicant_external_notes").val("");
        }

    });

    var stats_1 = $('#search_filter_status_1').val();
    var stats_2 = $('#search_filter_status_2').val();
    var stats_4 = $('#search_filter_status_4').val();
    var stats_rural = $('#search_filter_rural').val();

    var state_value = $('#search_filter_state').val();
    var zip_value = $('#search_filter_zipcode').val();
    var referral_code = $('#search_filter_referral_code').val();
    var referral_company = $('#search_filter_referral_company').val();

    $('#search_filter_status_1, #search_filter_status_2, #search_filter_status_4, #search_filter_rural, #search_filter_state, #search_filter_zipcode, #search_filter_referral_code, #search_filter_referral_company').on('click change', function(e){

        if (e.type == 'click'){
            if($('#search_filter_status_1').is(":checked")){
                stats_1 = "Not Yet Started";
            }else{
                stats_1 = null;
            }
            if($('#search_filter_status_2').is(":checked")){
                stats_2 = "In Progress";     
            }else{
                stats_2 = null;         
            }
            if($('#search_filter_status_4').is(":checked")){
                stats_4 = "Approved with Conditions";     
            }else{
                stats_4 = null;         
            }
            if($('#search_filter_rural').is(":checked")){
                stats_rural = "Rural";     
            }else{
                stats_rural = null;         
            }
        } else {
            state_value = $('#search_filter_state').val();            
            zip_value = $('#search_filter_zipcode').val();
            referral_code = $('#search_filter_referral_code').val();
            referral_company = $('#search_filter_referral_company').val();
        }

        var filter_arr = [stats_1, stats_2, stats_4, stats_rural, state_value, zip_value, referral_code, referral_company];
        filter_arr = filter_arr.filter(item => item !== null);
        console.log(filter_arr);
        var applicant_counts = 0;
        $("#applicants li").each(function() {
            console.log(filter_arr.every(item => $(this).text().search(item) > -1));
             if (filter_arr.every(item => $(this).text().search(item) > -1)){
                 $(this).show();
                 applicant_counts++;
             } else {
                 $(this).hide();
             }
         });
         $('h2.list-group-title').html("Selected Applicants (" + applicant_counts + ")");
    });
    
    $('form#applicant-status-form').submit(function(event) {
        if ($('#status').val() > 3)
            return confirm("This will send the applicant an email notice. Are you sure?");
        else
            return true;
    });

    $('#status2').on('change', function(){
        console.log($(this).val())
        if($(this).val() > 3 ){
            $("#external-note2").show();
        } else {
            $("#external-note2").hide();
        }
        if($(this).val() > 2 ){
            $('#submit-applicant').attr("disabled", false);
        }
    });
    
    $('form#applicant-status-form2').submit(function(event) {
        if ($('#status2').val() > 3)
            return confirm("This will send the applicant an email notice. Are you sure?");
        else
            return true;
    });

    $('form#applicant-status-form2').submit(function(event) {
        if ($('#status2').val() > 3)
            return confirm("This will send the applicant an email notice. Are you sure?");
        else
            return true;
    });

    $('#send-status-email').on('click', function(){
        $('.show-sent').html("Send Status Email ( Email Sent )");
    });


    $('.internal-note').on('input propertychange paste', function(){
        $('.internal-note-alert').html("Don't forget to save the note changes.");
    });

    $(document).ready(function() {
        $("select").addClass('form-select');
    });

    $(".Notary_Cert_1").show(); 
});

window.enableFile = function(filename) {
    $(".file-preview").hide();
    $(`.${filename}`).show();
    $(`iframe .${filename}`).attr('src', function ( i, val ) { return val; });
    $('button').removeClass('active');
    $(`#${filename}Heading`).addClass('active');
}

$('input, select, textarea').on('focus blur', function(event) {
  $('meta[name=viewport]').attr('content', 'width=device-width,initial-scale=1,maximum-scale=' + (event.type == 'blur' ? 10 : 1));
});

$('.modal-trigger').on('event', function() {
    $('.load').style.display = "block !important";
});
